<template>
  <el-container>
    <el-container>
      <el-aside width="auto">
        <SideBar />
      </el-aside>
      <el-container>
        <el-main class="main">
          <router-view v-slot="{ Component }">
            <keep-alive :max="1">
              <component
                :is="Component"
                v-if="$route.meta.keepAlive"
                :key="$route.name"
              />
            </keep-alive>
            <component
              :is="Component"
              v-if="!$route.meta.keepAlive"
              :key="$route.name"
            />
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useWindowScroll } from "@vueuse/core";
import NavGation from "./Nav.vue";
import SideBar from "./SideBar.vue";

export default defineComponent({
  name: "HomeFrame",
  components: {
    NavGation,
    SideBar,
  },

  setup() {
    const store = useStore();
    const { y } = useWindowScroll(); //可返回当前页面滚动时候滚动条的 x（横向)，y（纵向）坐标。
    const height = ref("");

    // 可以给body-right标签加mousemove事件，作为回调，小于1200px时，离开边栏时边栏自动收缩
    const changeMenu = () => {
      if (document.body.offsetWidth < 1200) {
        store.commit("openMenu", false);
      }
    };

    onBeforeMount(() => {
      const h = "${document.documentElement.clientHeight}";
      height.value = h;
    });

    return {
      store,
      y,
      height,
      changeMenu,
    };
  },
});
</script>

<style scoped>
.el-header {
  padding: 0 0;
}

.el-footer {
  margin-top: 40px;
  position: relative;
  bottom: 0;
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
  width: 100%;
  z-index: 10;
}

.el-aside {
  position: relative;
  /* overflow-y: scroll; */
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 100px;
}

.el-main {
  /* overflow: visible;
  height: 0; */
  padding: 0;
  background-color: white;
  min-height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto !important;
}

body > .el-container {
  margin-bottom: 40px;
}

.header {
  position: fixed;
  width: 100%;
  /* height: 67px; */
  z-index: 5;
}

.body {
  position: relative;
  top: 67px;
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 67px;
  width: 100%;
}

.el-header {
  padding-left: 0;
}

.wrap-all {
  padding: 50px 40px;
}

.buttons .el-sub-menu .el-sub-menu__title .el-sub-menu__icon-arrow {
  margin-left: 7px;
}
</style>
