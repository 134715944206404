<template>
  <el-menu
    :default-active="$route.path"
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    :class="y <= 0 ? 'nav_1' : 'nav_3'"
    router
    active-text-color="#0054fe"
    background-color="#ffffff"
    text-color="#909399"
  >
    <!-- 展开按钮 -->
    <div class="hamburger">
      <el-icon class="hamburger-icon" @click="showMenu">
        <el-icon
          :size="20"
          style="vertical-align: middle"
          v-if="store.state.openMenu === true"
        >
          <Fold />
        </el-icon>
        <el-icon :size="20" style="vertical-align: middle" v-else>
          <Expand />
        </el-icon>
      </el-icon>
    </div>
    <!-- 首页 -->
    <el-menu-item :index="'/index'">
      <el-icon><House /></el-icon>
      首页
    </el-menu-item>
    <!-- 设备 -->
    <el-menu-item :index="'/device'">
      <el-icon><Iphone /></el-icon>
      设备
    </el-menu-item>
    <!-- 列表 -->
    <!-- <el-sub-menu index="category">
      <template #title>
        <el-icon><Star /></el-icon>
        分类
      </template>
      <el-scrollbar height="300px">
        <el-menu-item
          v-for="item in ranks"
          :key="item.rank_id"
          :index="'/category/' + item.rank_id"
        >
          {{ item.rank_id }}
        </el-menu-item>
      </el-scrollbar>
    </el-sub-menu> -->

    <!-- 伸缩区域 -->
    <div class="flex-grow">
      <!-- <SearchBox class="search-box" /> -->
    </div>

    <div class="buttons">
      <el-sub-menu index="1">
        <template #title>
          <el-avatar
            class="el-avatar--medium"
            style="background: #409eff !important; margin-right: 5px"
          >
            {{
              store.state.userInfo.username &&
              store.state.userInfo.username.length > 1
                ? store.state.userInfo.username.substring(
                    store.state.userInfo.username.length - 2
                  )
                : store.state.userInfo.username
            }}
          </el-avatar>
          {{ store.state.userInfo.username }}
        </template>
        <el-menu-item index="1-1" @click="dialogUserInfo = true"
          >我的信息</el-menu-item
        >
        <!-- <el-menu-item index="1-2" @click="dialogChangePwd = true"
          >修改密码</el-menu-item
        > -->
        <el-menu-item index="1-3" @click="logout">注销</el-menu-item>
      </el-sub-menu>
    </div>
  </el-menu>

  <el-dialog title="我的信息" v-model="dialogUserInfo" width="420px" center>
    <el-form
      label-position="left"
      class="demo-table-expand"
      label-width="90px"
      style="margin-left: 10px; word-break: break-all"
    >
      <el-form-item label="ID">
        <span>{{ store.state.userInfo.id }}</span>
      </el-form-item>
      <el-form-item label="账号">
        <span>{{ store.state.userInfo.username }}</span>
      </el-form-item>
      <el-form-item label="用户名">
        <span>{{ store.state.userInfo.name }}</span>
      </el-form-item>
      <el-form-item label="角色">
        <el-tag size="small">
          {{
            store.state.userInfo.role
              ? store.state.userInfo.role.join(",")
              : "无"
          }}
        </el-tag>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { ref, reactive, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
// import { useWindowScroll } from "@vueuse/core";
import { getdata } from "@/service/getlist";
import { Fold, Expand } from "@element-plus/icons-vue";
import axios from "@/utils/axios.js";
import { ElMessageBox } from "element-plus";
// import SearchBox from "./SearchBox.vue";
export default {
  name: "NaviGtion",
  components: {
    Fold,
    Expand,
    //SearchBox,
  },
  setup() {
    const dialogUserInfo = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { y } = useWindowScroll(); //可返回当前页面滚动时候滚动条的 x（横向)，y（纵向）坐标。
    const rank = reactive({
      ranks: [],
    });
    const showMenu = () => {
      store.commit("openMenu", !store.state.openMenu);
    };
    const goToLogin = () => {
      router.push({ name: "login" });
    };

    onMounted(async () => {
      // let items = await getdata("/static/rank.json");
      // rank.ranks = items;
    });

    const logout = () => {
      ElMessageBox.confirm("确认注销?", "信息提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //请求退出接口
        axios.get("/logout").then((resp) => {
          // if (resp.code && resp.code == 200) {
          // }
        });
        //清空缓存信息
        store.commit("clearAuth");
        router.replace({ path: "/Login" });
      });
    };

    return {
      store,
      route,
      router,
      y,
      ...toRefs(rank),
      showMenu,
      goToLogin,
      logout,
      dialogUserInfo,
    };
  },
};
</script>

<style scoped>
/* 没下拉的顶栏 */
.nav_1 {
  z-index: 10;
  /* line-height: 67px; */
  width: 100%;
  background-color: white;
  /* height: 67px; */
}

/* 下拉的顶栏，有阴影 */
.nav_3 {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  line-height: 67px;
  width: 100%;
  background-color: white;
  height: 67px;
}

/* 目录收缩区域 */
.hamburger {
  min-width: 64px;
  width: 64px;
}

/* 目录收缩的图标 */
.hamburger-icon {
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}

/* 图标、按钮div */
.img {
  cursor: pointer;
  padding: 0 10px;
}

/* 图标 */
.img img {
  height: 40px;
}

/* 下拉内容区 */
.dropdown-content {
  display: none;
  position: absolute;
  margin-left: -10px;
  max-height: 320px;
  overflow-y: scroll;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
}

/* 下拉内容区显示 */
.dropdown:hover .dropdown-content {
  display: block;
}

/* 伸缩区域 */
.flex-grow {
  flex-grow: 1;
}

.search-box {
  max-width: 500px;
  margin-left: 10%;
}
</style>

<style>
.buttons .el-sub-menu .el-sub-menu__title .el-sub-menu__icon-arrow {
  position: static;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -3px;
}

.el-menu--horizontal {
  border-bottom: 0px !important;
  box-shadow: 0px 4px 4px 0px rgba(43, 45, 55, 0.01) !important;
}
</style>
