import axios from "@/utils/axios";

// 上传文件
export function paperUpload(data) {
    let IMPORT_URL = "/thesis/upload";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data: data,
        timeout: 30000
    })
}


// 获取省份
export function getProvince() {
    let IMPORT_URL = "/config/getProvince";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
    });
}

// 获取学校
export function getUnivercity(params) {
    let IMPORT_URL = "/university/getUnivercity";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}

// 查询学校模板
export function getCheckTemplate(code) {
    let IMPORT_URL = "/check-template/getCheckTemplate";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params: { code: code }
    });
}

// 获取检测项配置
export function getCheckTtems() {
    let IMPORT_URL = "/check-items/list";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
    });
}






