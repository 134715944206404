import { createStore } from "vuex";
import { getCookie, setCookie, removeCookie } from "@/service/common.js";
// import state from "./state";
// import actions from "./actions";
// import mutations from "./mutations";

export default createStore({
  state: {
    userInfo: getCookie("userInfo") ?? {},
    token: getCookie("userToken") ?? "",
    openMenu: true,
  },
  mutations: {
    openMenu(state, nowOpen) {
      state.openMenu = nowOpen;
    },
    changeLogin(state, userToken) {
      state.token = userToken;
      setCookie("userToken", userToken, '8h');
    },
    setUserInfo(state, userInfo) {
      setCookie("userInfo", userInfo);
      state.userInfo = userInfo;
    },
    clearAuth(state) {
      state.token = "";
      state.userInfo = {};
      removeCookie("userToken");
      removeCookie("userInfo");
    },
    setLocaleInfo(state, info) {
      state.localInfo = info;
    },
  },
  actions: {
    asyncOpenMenu(store, payload) {
      // 第一个参数是vuex固定的参数，不需要手动去传递
      store.commit("openMenu", payload);
    },
  },
  modules: {},
});
