import axios from "axios";
import { ElMessage } from "element-plus";
import { router } from "../router/index";
import { getCookie, removeCookie } from "@/service/common.js";


axios.defaults.baseURL = window.apiObj.VUE_APP_BASE_API_URL;
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = false;

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Accept-Language": "*",

    };
    if (getCookie("userToken")) {
      config.headers.Authorization = getCookie("userToken");
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 设置响应拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.status && res.status == 200) {
      if (res.headers.authorization) {
        res.data.authorization = res.headers.authorization;
      }
      if (
        res.data.code == 500 ||
        res.data.code == 401 ||
        res.data.code == 403
      ) {
        ElMessage({
          showClose: false,
          message: res.data.msg,
          type: "error",
        });
        // return;
      } else if (res.data.message) {
        // ElMessage({
        //   showClose: true,
        //   message: res.data.message,
        //   type: "success",
        // });
      } else if (res.data.code == 512) {
        ElMessage.error('登陆状态过期，请重新登录')
        router.push('/login');
        removeCookie("userToken");
        return
      } else {
        // ElMessage({
        //   showClose: false,
        //   message: res.data.msg,
        //   type: "error",
        // });
      }
    }
    return res.data;
  },
  (error) => {
    if (error.response && error.response.data.code == 512) {
      ElMessage.error('登陆状态过期，请重新登录')
      router.push('/login');
      removeCookie("userToken");
      return
    }
    //由于网页的二次刷新会导致第一次请求被取消
    if (!error.response) {
      // console.log("在请求发送方产生了异常");
      // console.log(JSON.stringify(error));
      // if (error.message) {
      //   ElMessage({
      //     showClose: true,
      //     message: "请求失败：" + error.message,
      //     type: "error",
      //   });
      // }
    } else if (error.response.status == 504 || error.response.status == 404) {
      ElMessage({
        showClose: false,
        message: "没有找到服务器",
        type: "error",
      });
    } else if (error.response.status == 403) {
      ElMessage({
        showClose: false,
        message: "权限不足，请联系管理员",
        type: "error",
      });
    } else if (error.response.status == 401 || error.response.status == 400) {
      ElMessage({
        message: error.response.data.msg,
        type: "error",
      });
    } else if (
      error.response.status == 500
    ) {
      ElMessage({
        showClose: false,
        message: "登录已失效，请重新登录",
        type: "error",
      });
      //清空缓存信息
      removeCookie("userToken");
      removeCookie("userInfo");
      location.reload();
    } else {
      // if (error.response.data.msg) {
      //   ElMessage({
      //     showClose: false,
      //     message: error.response.data.msg,
      //     type: "error",
      //   });
      // } else {
      //   console.log(error.response);
      // }
      // console.log(error.response);
    }
    return;
  }
);

export default axios;
