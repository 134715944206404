import axios from "@/utils/axios";

// 论文列表
export function getThesises(params) {
    let IMPORT_URL = "/thesis/list";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}

// 删除论文
export function deleteThesis(checkSn) {
    let IMPORT_URL = "/thesis/delete";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data: { checkSn }
    });
}

// 付费查看论文
export function paytoCheck(checkSn) {
    let IMPORT_URL = "/thesis/paytoCheck";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data: { checkSn }
    });
}


