import { createApp } from "vue";
import App from "./App.vue";
// import Vue from "vue";
// 引入elementplus插件
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// 引入iconfont字体图标
import "./assets/font/iconfont.css";

// 引入路由器
import { setupRouter, router } from "./router/index.js";

// 引入vuex
import store from "./store/index.js";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import zhCn from "element-plus/es/locale/lang/zh-cn";

import appConfig from "@/config/config.js";
import { ElMessage } from "element-plus";

// 引入字节图标
import * as ElIconModules from "@icon-park/vue-next";
import "@icon-park/vue-next/styles/index.css";

// 引入全局样式
import "./assets/css/global.css";

async function initApp() {
  const app = createApp(App);
  // 初始化路由
  setupRouter(app);

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }

  for (const [key, component] of Object.entries(ElIconModules)) {
    app.component(key + "Icon", component);
  }

  app.use(ElementPlus, { locale: zhCn }).use(store).mount("#app");

  // 屏蔽警告信息
  app.config.warnHandler = () => null;
}

// 创建应用
initApp().then(() => {
  router.beforeEach((to, from, next) => {
    document.title = appConfig.title;

    if (to.meta["title"]) {
      document.title += " - " + to.meta["title"];
    }
    if (store.state.token.length !== 0 && to.path === "/Login") {
      ElMessage({
        showClose: true,
        message: "已登录",
        type: "warning",
      });
      next({
        path: "/",
      });
    }
    //当页面存在才能进入
    if (to.meta.existRequest) {
      // 设置应用详情需登录才能查看
      if (to.meta.loginRequest) {
        if (store.state.token.length === 0 && to.path !== "/Login") {
          ElMessage({
            message: "请先登录",
            type: "warning",
          });
          next({
            path: "/Login",
            query: { redirect: to.fullPath },
          });
        } else {
          next();
        }
      } else next();
    } else {
      router.push("/");
    }
  });
});

// 兼容Chrome超出了ResizeObserver循环限制
window.ResizeObserver = class _NewResizeObserver extends ResizeObserver {
  constructor(callback) {
    super(() =>
      window.requestAnimationFrame(() => callback.apply(this, arguments))
    );
  }
};
