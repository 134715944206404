import axios from "@/utils/axios";

// 获取公众号二维码
export function getLoginQrCode() {
    let IMPORT_URL = "/wx/getLoginQrCode";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
    });
}

// 获取openId检测是否扫码关注
export function getOpenId(id) {
    let IMPORT_URL = "/wx/getOpenId";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        params: { eventKey: id },
    });
}




