// 枚举用于格式化参数
export default {
    detectionType: [
        {
            key: "cover",
            label: "封面",
            time: '1分钟'
        },
        {
            key: "Abstract",
            label: "摘要",
            time: '2分钟'
        },
        {
            key: "Catalog",
            label: "目录",
            time: '1分钟'
        },
        {
            key: "Body",
            label: "正文",
            time: '2分钟'
        },
        {
            key: "TitleChapter",
            label: "论文标题和章节标题",
            time: '3分钟'
        },
        {
            key: "PaperStructure",
            label: "论文结构",
            time: '2分钟'
        },
        {
            key: "PageLayout",
            label: "页面设置",
            time: '3分钟'
        },
        {
            key: "Paragraph",
            label: "段落",
            time: '2分钟'
        },
        {
            key: "Sentences",
            label: "句子",
            time: '3分钟'
        },
        {
            key: "cfm",
            label: "公式",
            time: '3分钟'
        },
        {
            key: "cgm",
            label: "图",
            time: '5分钟'
        },
        {
            key: "ctm",
            label: "表格",
            time: '3分钟'
        },
        {
            key: "Algorithm",
            label: "算法",
            time: '2分钟'
        },
        {
            key: "Conclusion",
            label: "结论和展望",
            time: '3分钟'
        },
        {
            key: "Reference",
            label: "参考文献",
            time: '2分钟'
        },
        {
            key: "Acknowledgement",
            label: "致谢",
            time: '1分钟'
        },
    ],
    detection: {
        cover: {
            title: '封面',
        },
        Abstract: {
            title: '摘要',
        },
        Catalog: {
            title: '目录',
        },
        Body: {
            title: '正文',
        },
        TitleChapter: {
            title: '论文标题和章节标题',
        },
        PaperStructure: {
            title: '论文结构',
        },
        PageLayout: {
            title: '页面设置',
        },
        Paragraph: {
            title: '段落',
        },
        Sentences: {
            title: '句子',
        },
        cfm: {
            title: '公式',
        },
        cgm: {
            title: '图',
        },
        ctm: {
            title: '表格',
        },
        Algorithm: {
            title: '算法',
        },
        Conclusion: {
            title: '结论和展望',
        },
        Reference: {
            title: '参考文献',
        },
        Acknowledgement: {
            title: '致谢',
        },
    },

    schoolTemplate: [
        {
            key: "common",
            value: "通用模板",
        },
        {
            key: "YiKe",
            value: "医科大学模板",
        },
        {
            key: "HuaGongBenKe",
            value: "本科学位模板",
        },
        {
            key: "HuaGong",
            value: "硕士学位模板",
        },
        {
            key: "HuaGongBoShi",
            value: "博士学位模板",
        },
        {
            key: "HuaNongBenKe",
            value: "本科学位模板",
        },

    ],

    detectionResult: [
        {
            key: "0",
            value: "未知",
        },
        {
            key: "1",
            value: "合格",
        },
        {
            key: "2",
            value: "不合格",
        },

    ]



}