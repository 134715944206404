// 用于创建路由
import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";
import VueCookies from "vue-cookies";
import { ElMessage } from "element-plus";
// 引入nprogress样式
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import { isNewVersion } from "@/service/common.js";

// NProgress.configure({
//   easing: 'ease',
//   speed: 500,
//   // showSpinner: false,
//   trickleSpeed: 200,
//   minimum: 0.3
// })

let head = document.getElementsByTagName("head");
let meta = document.createElement("meta");
meta.name = "referrer";
//根据实际情况修改referrer的值，可选值参考上文
meta.content = "no-referrer";
head[0].appendChild(meta);

const getRoutes = () => [
  { path: "/", redirect: "/login" },
  {
    name: "login",
    path: "/login",
    component: () => import("@/pages/login.vue"),
    meta: {
      title: "登录",
      loginRequest: false,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "index",
    path: "/index",
    component: () => import("@/pages/Index.vue"),
    meta: {
      title: "首页",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "paper_detection",
    path: "/paper_detection",
    component: () => import("@/pages/PaperDetection.vue"),
    meta: {
      title: "论文检测",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "history",
    path: "/history",
    component: () => import("@/pages/history.vue"),
    meta: {
      title: "历史记录",
      loginRequest: true,
      keepAlive: true,
      existRequest: true,
      isBack: false
    },
  },
  {
    name: "test_report",
    path: "/test_report",
    component: () => import("@/pages/TestReport.vue"),
    meta: {
      title: "检测报告",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,

    },
  },
  {
    name: "thesis_assistant",
    path: "/thesis_assistant",
    component: () => import("@/pages/ThesisAssistant.vue"),
    meta: {
      title: "论文助手",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "personal_center",
    path: "/personal_center",
    component: () => import("@/pages/PersonalCenter.vue"),
    meta: {
      title: "个人中心",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "help_center",
    path: "/help_center",
    component: () => import("@/pages/HelpCenter.vue"),
    meta: {
      title: "帮助中心",
      loginRequest: true,
      keepAlive: false,
      existRequest: true,
    },
  },
  {
    name: "report_verify",
    path: "/report_verify",
    component: () => import("@/pages/ReportVerify.vue"),
    meta: {
      title: "报告查验",
      loginRequest: false,
      keepAlive: false,
      existRequest: true,
    },
  },

];


//创建并暴露一个路由器
//loginRequest 用于判断是否需要登陆
//keepAlive 判断是否有导航系统
//existRequest: 判断页面是否存在，错误url将重定位到首页
//isBack 判断是否从详情页返回该页面

export let router = null;

export function setupRouter(app) {
  router = createRouter({
    history: createWebHistory(),
    routes: getRoutes(),
  });

  app.use(router);

  // 路由守卫
  router.beforeEach((to, from, next) => {
    NProgress.start()
    const token = VueCookies.get('userToken')
    // token存在
    if (token) {
      if (to.path != '/login') return next()
      if (to.path == '/login') return next('/index') // 通过浏览器url跳转登录页 强制返回首页
    }
    // token不存在
    if (!token) {
      if (to.meta.loginRequest == true) ElMessage.error('登录状态过期，请重新登录')
      if (to.path == '/login') return next();
      if (to.path == '/report_verify') return next()
    }
    return next('/login') // 返回登录页
  })

  router.afterEach(() => {
    NProgress.done()
  })

}




