import axios from "@/utils/axios";

// 发送验证码
export function sendSms(params) {
    let IMPORT_URL = "/msm/sendSms";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params,
    });
}

// 校验验证码
export function checkCode(params) {
    let IMPORT_URL = "/msm/checkCode";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}

// 重置密码
export function resetPaw(params) {
    let IMPORT_URL = "/user/resetPaw";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}

// 获取用户信息
export function getUser() {
    let IMPORT_URL = "/user/getUser";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
    });
}

// 充值次数列表
export function checknumCombo() {
    let IMPORT_URL = "/checknum-combo/list";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
    });
}

// 下单充值
export function checknumRecharge(params) {
    let IMPORT_URL = "/checknum-combo/recharge";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}


// 消费记录
export function checknumRecord(params) {
    let IMPORT_URL = "/unified-order/record";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params
    });
}

// 查询支付状态
export function queryPayResult(orderNumber) {
    let IMPORT_URL = "/unified-order/queryPayResult";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params: { orderNumber: orderNumber }
    });
}

// 获取学校
export function getSchool() {
    let IMPORT_URL = "/user/getSchool";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
    });
}

// 保存完善信息
export function updateUser(data) {
    let IMPORT_URL = "/user/updateUser";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data
    });
}

// 获取免费次数
export function getFreeNum() {
    let IMPORT_URL = "/user/getFreeNum";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
    });
}

// 推荐码保存
export function SaveReferralCode(data) {
    let IMPORT_URL = "/user-relations/referralCode";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data
    });
}

