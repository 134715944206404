<template>
  <div class="main">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
    <h5>检测中</h5>
    <p>论文检测中，请您耐心等待</p>
  </div>
</template>

<script setup></script>

<style scoped>
.spinner {
  width: 65px;
  height: 65px;
  text-align: center;
}

.spinner > div {
  background-color: #0054fe;
  height: 100%;
  width: 6px;
  margin: auto 2px;
  display: inline-block;

  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.main h5 {
  font-size: 16px;
  line-height: 20px;
  color: rgba(40, 45, 48, 1);
  margin-bottom: 0;
}

.main p {
  font-size: 12px;
  line-height: 20px;
  color: rgba(155, 162, 171, 1);
}
</style>
