import axios from "@/utils/axios";

// 论文报告详情
export function getThesisId(checkSn) {
    let IMPORT_URL = "/thesis-detail/detail";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data: { checkSn }
    });
}

// 论文详情
export function getThesisDetail(checkSn) {
    let IMPORT_URL = "/thesis/detail";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data: { checkSn }
    });
}

// 论文问题描述
// export function getdescribe(templateType) {
//     let IMPORT_URL = "/config/getdescribe";
//     return axios.request({
//         method: "get",
//         url: IMPORT_URL,
//         params: { confKey: templateType }

//     });
// }

// 下载检测报告
export function getDownloadReport(checkSn) {
    let IMPORT_URL = "/thesis/downloadReport";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params: { checkSn: checkSn }
    });
}

// 查询报告
export function selectReport(checkSn) {
    let IMPORT_URL = "/thesis/selectReport";
    return axios.request({
        method: "get",
        url: IMPORT_URL,
        params: { checkSn: checkSn }
    });
}
