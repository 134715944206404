import axios from "@/utils/axios";

// 意见反馈
export function feedbackSend(params) {
    let IMPORT_URL = "/feedback/send";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        params
    });
}

// 上传图片
export function uploadImg(data) {
    let IMPORT_URL = "/feedback/uploadImg";
    return axios.request({
        method: "post",
        url: IMPORT_URL,
        data
    });
}





