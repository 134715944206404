// 存放公用函数
// import router from "@/router/index";
import VueCookies from "vue-cookies";
import axios from "axios";
// import appConfig from "@/config/config";
import path from "path";
import { ElNotification } from 'element-plus'
import { h } from 'vue'


export function goToDetail(item) {
  router.push({
    name: "iteminfo",
    params: { id: item.id },
  });
}

export const getLocal = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

export const setLocal = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getCookie = (name) => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(" electron/") > -1) {
    return sessionStorage.getItem(name) ?? null;
  } else {
    return VueCookies.get(name) ?? null;
  }
};

export const setCookie = (name, value, time) => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(" electron/") > -1) {
    sessionStorage.setItem(name, value) ?? null;
  } else {
    VueCookies.set(name, value, time);
  }
};

export const removeCookie = (name) => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(" electron/") > -1) {
    sessionStorage.remove(name);
  } else {
    return VueCookies.remove(name);
  }
};

export const findItem = (items, str) => {
  if (!items.length) return [];
  else if (!str) return items;
  let keys = Object.keys(items[0]);
  let res = [];
  let flag = false;
  items.forEach((item) => {
    flag = false;
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof item[key] == "string")
        if (item[key].indexOf(str) != -1) {
          flag = true;
          res.push(item);
          break;
        }
    }
    if (flag) return;
  });
  return res;
};

export const getApiUrl = (url) => {
  return url.indexOf("http") != -1 ? url : process.env.VUE_APP_BASE_API_URL + url;
};

export const getImgUrl = (url) => {
  return getResourcesUrl(url);
};

export const getResourcesUrl = (url) => {
  return url.indexOf("http") != -1 ? url : process.env.VUE_APP_BASE_IMAGE_URL + url;
};

export const getPathBasename = (pathDir) => {
  pathDir = (pathDir + "").replaceAll("\\", "/");
  return path.basename(pathDir);

};

export const downloadHand = (url, params, name) => {
  axios({
    method: "get",
    url: url,
    params: params,
    responseType: "blob",
  })
    .then((res) => {
      if (!res) {
        ElMessage.error("报告下载失败");
        return;
      }
      const content = res;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, name);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// 防抖函数
export function debounce(fn, delay) {
  let timer = null;
  return function () {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(); // 执行传入的函数
    }, delay);
  };
}

// 清除定时器函数
export function clearTimer(timer) {
  if (timer) {
    clearTimeout(timer);
    clearInterval(timer);
    timer = null
  }
}


// 部署版本更新提示函数
export const isNewVersion = () => {
  let url = `//${window.location.host}/version.json?t=${new Date().getTime()}`
  axios.get(url).then(res => {
    let localVersion = localStorage.getItem('localVersion')
    if (localVersion) {
      if (localVersion != res.version) {
        ElNotification({
          title: "系统提示",
          type: "warning",
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: h("p", null, [
            h("span", "系统版本升级，为了不影响您的正常使用！ "),
            h(
              "a",
              {
                style: "color: #0054fe; cursor: pointer",
                onClick() {
                  window.location.reload(true);
                  localStorage.setItem('localVersion', res.version)
                },
              },
              "  请点击此处刷新"
            ),
          ]),
        },);
        return
      }
    } else {
      localStorage.setItem('localVersion', res.version)
    }

  })
}

